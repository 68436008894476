.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker-wrapper input {
  width: 200px;
}

.rdw-dropdown-selectedtext {
  min-width: 60px;
}
